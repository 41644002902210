
import { Options, Vue, setup } from "vue-class-component";
import { Form, Field, CellGroup, Button, Toast } from "vant";
import { ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ERROR_CODE } from "@/const/errorCode";
import { tokenLogin } from "@/const";
import { AllState } from "../../store";

interface LoginFormParams {
  user: string;
  pwd: string;
  success?: () => void;
  error?: () => void;
}

@Options({
  components: {
    Form,
    Field,
    CellGroup,
    Button
  }
})
export default class Login extends Vue {
  login = setup(() => {
    const store = useStore<AllState>();
    const username = ref<string>("");
    const password = ref<string>("");
    const isTokenLogin = ref(tokenLogin);
    const router = useRouter();

    let loading = ref<boolean>(false);

    const login = (opt: any) => {
      // 登录服务器
      loading.value = true;
      const option = tokenLogin
        ? {
            user: opt.user,
            accessToken: opt.pwd
          }
        : opt;
      store.state.IM.connect
        .open(option)
        .then((res) => {
          window.localStorage.setItem("uid", username.value);
          window.localStorage.setItem("token", res.accessToken);
          router.push("/chat");
        })
        .catch((e) => {
          console.log(e, 'eee')
          let errInfo: any = {};
          if (e.data.data) {
            errInfo = JSON.parse(e.data.data);
          }
          if (errInfo.error_description === ERROR_CODE.invalidPassword) {
            Toast("用户名或密码错误");
          } else if (errInfo.error_description === ERROR_CODE.userNotFound) {
            Toast("用户不存在");
          } else {
            Toast("登录失败");
          }
        })
        .finally(() => {
          loading.value = false;
        });
    };

    const onSubmit = (params: LoginFormParams): void => {
      login(params);
    };

    const toRegister = (): void => {
      router.push("/register");
    };

    const toConfig = (): void => {
      router.push("/config");
    };

    return {
      username,
      password,
      isTokenLogin,
      loading,
      toRegister,
      toConfig,
      onSubmit
    };
  });
}
