import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-715ec49e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "loginWrap" }
const _hoisted_2 = { style: {"margin":"16px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Field = _resolveComponent("Field")!
  const _component_CellGroup = _resolveComponent("CellGroup")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Form = _resolveComponent("Form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "wel",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.login.toConfig && _ctx.login.toConfig(...args)))
    }, "VolcanO"),
    _createVNode(_component_Form, {
      onSubmit: _ctx.login.onSubmit
    }, {
      default: _withCtx(() => [
        _createVNode(_component_CellGroup, { inset: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_Field, {
              modelValue: _ctx.login.username,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.login.username) = $event)),
              name: "user",
              label: "用户名",
              placeholder: "用户名",
              rules: [{ required: true, message: '请填写用户名' }]
            }, null, 8, ["modelValue"]),
            _createVNode(_component_Field, {
              modelValue: _ctx.login.password,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.login.password) = $event)),
              type: "password",
              name: "pwd",
              label: _ctx.login.isTokenLogin ? 'Token' : '密码',
              placeholder: _ctx.login.isTokenLogin ? 'Token' : '密码',
              rules: [{ required: true, message: '请填写密码' }]
            }, null, 8, ["modelValue", "label", "placeholder"])
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Button, {
            loading: _ctx.login.loading,
            round: "",
            block: "",
            type: "primary",
            "native-type": "submit"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" 登录 ")
            ]),
            _: 1
          }, 8, ["loading"])
        ])
      ]),
      _: 1
    }, 8, ["onSubmit"]),
    _createElementVNode("div", {
      class: "toRegister",
      onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.login.toRegister && _ctx.login.toRegister(...args)))
    }, "没有账号？去注册")
  ]))
}